<template>
  <div>
    <a-form-model ref="searchFrom" :model="search_form" layout="inline">
      <a-form-model-item label="规则名称">
        <a-input
          placeholder="请输入"
          v-model.trim="search_form.rule_name"
          :maxLength="100"
          style="width: 250px"
        />
      </a-form-model-item>
      <a-form-model-item label="状态">
        <a-select
          v-model="search_form.status"
          style="width: 250px"
          placeholder="请选择"
          allow-clear
        >
          <a-select-option
            :value="el.value"
            v-for="el in statusList"
            :key="el.value"
          >
            {{ el.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="提交时间">
        <a-range-picker
          :placeholder="['开始时间', '结束时间']"
          show-time
          format="YYYY-MM-DD"
          inputReadOnly
          @change="onChangeDate"
          style="width: 250px"
          :disabled-date="disabledDate"
          :value="search_time"
          :ranges="{
            今日: [$moment().startOf('day'), $moment()],
            本月: [$moment().startOf('month'), $moment().endOf('day')],
          }"
        />
      </a-form-model-item>
    </a-form-model>
    <div class="mtp">
      <a-button class="ml-10p" :icon="'redo'" @click="resetSearch" type="dashed"
        >重置</a-button
      >
      <a-button
        class="ml-10p"
        :type="'primary'"
        :icon="'search'"
        @click="searchClick"
      >
        查询
      </a-button>
    </div>
    <nm-table
      :configPayload="configPayload"
      :selectValue="search_form"
      ref="nmtable"
    >
      <div slot="status" slot-scope="text">
        <a-tag v-if="text.status == 2" color="blue"> 导出中 </a-tag>
        <a-tag v-if="text.status == 3" color="green"> 导出成功 </a-tag>
        <a-tag v-if="text.status == 4" color="pink"> 导出失败 </a-tag>
      </div>
      <div slot="action" slot-scope="text">
        <a-button v-if="text.status == 3" type="link" @click="dowloadExport(text.url)"
          >下载</a-button
        >
        <a-button
          v-if="text.status == 4"
          type="link"
          @click="readCause(text.error_msg)"
          >查看失败原因</a-button
        >
      </div>
    </nm-table>
  </div>
</template>
<script>
import _http from "@/api/core/Analyse.js";
import NmTable from "@/components/nm-table.vue";
const configPayload = {
  columns: [
    {
      title: "规则名称",
      dataIndex: "rule_name",
    },
    {
      title: "状态",
      dataIndex: "status",
      scopedSlots: { customRender: "status" },
    },
    {
      title: "提交时间",
      dataIndex: "ctime",
    },
    {
      title: "导出完成时间",
      dataIndex: "finish_time",
    },
    {
      title: "操作",
      dataIndex: "member_count",
      scopedSlots: { customRender: "action" },
    },
  ],
  http_api: _http.exportExport,
};
export default {
  components: {
    NmTable,
  },
  data() {
    return {
      configPayload,
      search_form: {
        rule_name: "",
        status: undefined,
        // time: "",
        ctime_from: "",
        ctime_to: "",
      },
      search_time: [],
      statusList: [
        {
          value: 2,
          name: "执行中",
        },
        {
          value: 3,
          name: "已完成",
        },
        {
          value: 4,
          name: "失败",
        },
      ],
    };
  },
  // mounted() {
  //   console.log("this.$api.code.Analyse:", _http.getRuleTable);
  // },

  methods: {
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > this.$moment().endOf("day");
    },
    //日期选择框
    onChangeDate(dates) {
      this.search_time = dates;
      let ctime_from = "";
      let ctime_to = "";
      if (dates.length) {
        ctime_from = this.$moment(dates[0]._d).format("YYYY-MM-DD");
        ctime_to = this.$moment(dates[1]._d).format("YYYY-MM-DD");
        // this.search_form.time[0] = ctime_from;
        // this.search_form.time[1] = ctime_to;
      }
      this.search_form.ctime_to = ctime_to;
      this.search_form.ctime_from = ctime_from;
    },
    async resetSearch() {
      await this.resetData();
      this.$refs.nmtable.getTableList(1, 10);
    },
    resetData() {
      this.search_form = {
        rule_name: "",
        status: undefined,
        ctime_from: "",
        ctime_to: "",
      };
      this.search_time = [];
    },
    searchClick() {
      this.$refs.nmtable.getTableList(1, 10);
    },
    readCause(value) {
      this.$error({
        title: "导出失败，请联系技术人员",
        content: value,
      });
    },
    dowloadExport(url) {
      if (url) {
        window.open(url);
      } else {
        this.$message.error("链接不存在","_blank");
      }
    },
  },
};
</script>
<style lang="less">
.flex-around {
  display: flex;
  justify-content: space-around;
}
.flex-wrap {
  display: flex;
  justify-content: space-between;
}
.ml-10p {
  margin-left: 10px;
}
.mtp {
  margin: 20px 16px 20px 0;
  display: flex;
  justify-content: flex-end;
}
</style>